/* src/fonts.css */
@font-face {
    font-family: 'Book Antiqua';
    src: url('./fonts/BookAntiqua.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Monotype Corsiva';
    src: url('./fonts/MonotypeCorsiva.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }